<template>
  <v-container fluid v-if="voyage">
    <v-row align="center">
      <v-col cols="8" class="text-h4 font-weight-light mb-1">
        {{ voyage.code }}
      </v-col>
      <v-col cols="4" :class="'mb-0 text-h5 font-weight-regular d-flex align-center justify-end ' + voyageStatusColor + 'Font--text'">
        <v-icon class="mr-2" :color="voyageStatusColor + ' darken-2'">{{ voyageStatusIcon }}</v-icon>
        {{ voyageStatus }}
      </v-col>
    </v-row>
    <v-divider class="grey lighten-2 mb-3" />
    <v-row align="center">
      <v-col cols="4" class="d-flex align-center">
        <v-text-field :label="tLabel('Unit number, order..')" v-model="utiFilter" clearable dense />
        <v-btn x-small outlined fab right color="primary" elevation="3" class="ml-6">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12" class="pt-0">
        <v-simple-table height="360" fixed-header dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-uppercase text-left">{{ tLabel("Unit") }}</th>
                <th class="text-uppercase text-left">{{ tLabel("Size type") }}</th>
                <th class="text-uppercase text-left">{{ tLabel("Operator") }}</th>
                <th class="text-uppercase text-left">{{ tLabel("Order") }}</th>
                <th class="text-uppercase text-left" v-if="voyage.direction === 'DEPARTURE'">{{ tLabel("POL") }}</th>
                <th class="text-uppercase text-left" v-if="voyage.direction === 'DEPARTURE'">{{ tLabel("SPOD") }}</th>
                <th class="text-uppercase text-left" v-if="voyage.direction === 'DEPARTURE'">{{ tLabel("POD") }}</th>
                <th class="text-uppercase text-left">{{ tLabel("Position") }}</th>
                <th class="text-uppercase text-left">{{ tLabel("Gross weight") }}</th>
                <th class="text-uppercase text-left" v-if="voyage.direction === 'DEPARTURE'">{{ tLabel("VGM") }}</th>
                <th class="text-uppercase text-left">{{ tLabel("Full/Empty") }}</th>
                <th class="text-uppercase text-left">{{ tLabel("Reefer") }}</th>
                <th class="text-uppercase text-left">{{ tLabel("IMDG") }}</th>
                <th class="text-uppercase text-left">{{ tLabel("Damaged") }}</th>
                <th class="text-uppercase text-left">{{ tLabel("Blocked") }}</th>
                <th class="text-uppercase text-left">{{ tLabel("Status") }}</th>
                <th class="text-uppercase text-left">{{ tLabel("Traffic type") }}</th>
              </tr>
            </thead>
            <tbody v-if="utiListFiltered !== null && utiListFiltered.length > 0">
              <tr v-for="uti in utiListFiltered" :key="uti.code">
                <td>{{ uti.code }}</td>
                <td>{{ uti.sizeType }}</td>
                <td>{{ uti.operator }}</td>
                <td>{{ uti.order }}</td>
                <td v-if="voyage.direction === 'DEPARTURE'">{{ uti.pol }}</td>
                <td v-if="voyage.direction === 'DEPARTURE'">{{ uti.spod }}</td>
                <td v-if="voyage.direction === 'DEPARTURE'">{{ uti.pod }}</td>
                <td>{{ uti.position }}</td>
                <td>{{ uti.grossWeight }}</td>
                <td v-if="voyage.direction === 'DEPARTURE'">{{ uti.vgm }}</td>
                <td>{{ uti.fullEmpty }}</td>
                <td>{{ uti.reefer }}</td>
                <td>{{ uti.imdg }}</td>
                <td>{{ uti.damaged }}</td>
                <td>{{ uti.blocked }}</td>
                <td>{{ uti.status }}</td>
                <td>{{ uti.trafficType }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td :colspan="voyage.direction === 'DEPARTURE' ? 13 : 17">{{ tLabel("No uti.") }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col cols="6"></v-col>
      <v-col cols="6">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <td :class="voyageStatusColor + ' lighten-5'"></td>
                <td :class="'text-subtitle-2 text-uppercase text-center ' + voyageStatusColor + ' lighten-3'" v-for="colHead in countColKeys" :key="colHead">{{ colHead }}</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="rowHead in countRowKeys" :key="rowHead">
                <td :class="'text-subtitle-2 text-uppercase text-center ' + voyageStatusColor + ' lighten-3'">{{ rowHead }}</td>
                <td class="text-uppercase text-right" v-for="colVal in countColKeys" :key="colVal">{{ voyage.utiCountMap[rowHead][colVal].worked }}/{{ voyage.utiCountMap[rowHead][colVal].total }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VesselMixin from "../../mixins/vessel/VesselMixin";

export default {
  name: "VoyagePanel",
  data() {
    return {
      utiFilter: null,
    };
  },
  props: {
    voyage: {
      type: Object,
      required: true,
    },
  },
  computed: {
    utiListFiltered() {
      return JSON.parse(JSON.stringify(this.voyage.utiList));
    },
    voyageStatusColor() {
      return this.voyage.direction === "ARRIVAL" ? this.dischargeStatusColor(this.voyage.callStatus) : this.loadStatusColor(this.voyage.callStatus);
    },
    voyageStatusIcon() {
      return this.voyage.direction === "ARRIVAL" ? this.dischargeStatusIcon(this.voyage.callStatus) : this.loadStatusIcon(this.voyage.callStatus);
    },
    voyageStatus() {
      return this.voyage.direction === "ARRIVAL" ? this.dischargeStatus(this.voyage.callStatus) : this.loadStatus(this.voyage.callStatus);
    },
    countRowKeys() {
      return Object.keys(this.voyage.utiCountMap);
    },
    countColKeys() {
      return this.countRowKeys ? Object.keys(this.voyage.utiCountMap[this.countRowKeys[0]]) : [];
    },
  },
  mixins: [VesselMixin],
};
</script>

