<template>
  <div>
    <v-container fluid style="height: calc(100vh - 88px)" class="grey lighten-4">
      <v-row align="center" no-gutters>
        <v-col cols="12">
          <v-card rounded="0">
            <v-card-text>
              <v-container fluid class="my-0 py-0">
                <v-row align="center">
                  <v-col cols="1" class="d-flex align-center">
                    <v-icon size="35" @click="goToCalls">mdi-arrow-left</v-icon>
                  </v-col>
                  <v-col cols="5" class="text-h5 text-uppercase font-weight-regular">
                    {{ callId  ? "Edit call " + callId : "Create call" }}
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-btn color="success" @click="saveCall"> <v-icon class="pr-2">mdi-content-save</v-icon>{{ tLabel("Salva") }} </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row align="center" no-gutters style="height: calc(100vh - 192px)">
        <v-col cols="12" class="fill-height">
          CALL ID: {{ callId }} <br />
          STATUS: {{ status }}  <br />
          <!-- CALL: {{ call }}<br /> -->
          VESSELS: {{ vessels }} <br />
          VESSEL SELECTED: {{ vesselSelected }}
          <v-form ref="editForm">
            <v-container fluid>
              <v-row align="center">
                <v-col cols="3">
                  <!--  -->
                  <v-combobox
                    :label="tLabelRequired('Vessel')"
                    v-model="vesselSelected"
                    :placeholder="tLabel('placeholder.atLeastThreeChars')"
                    :items="vessels"
                    :search-input.sync="searchVessel"
                    @change="onChangeVessel"
                    return-object
                    item-text="name"
                    :rules="[rules.required]"
                    clearable
                  >
                    <template v-slot:item="{ index, item }">
                      <v-list-item-content>
                        <v-list-item-title> {{ item.name }}: {{ item.imo }} {{ item.callSign }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="3">
                  <v-text-field label="Position" v-model="call.position" />
                </v-col>
                <v-col cols="3">
                  <v-text-field label="Operator" v-model ="call.operator" />
                </v-col>
                <v-col cols="3">
                  <v-text-field label="Agent" v-model="call.agent" />
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="3">
                  <v-text-field label="Voyage IN" v-model="call.voyageIn" />
                </v-col>
                <v-col cols="3">
                  <DateTimeAdapter label="ETA" v-model="call.eta" />
                </v-col>
                <v-col cols="3">
                  <v-text-field label="Voyage OUT" v-model="call.voyageOut" />
                </v-col>
                <v-col cols="3">
                  <DateTimeAdapter label="ETD" v-model="call.etd" />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DateTimeAdapter from "../../components/anagrafiche/DateTimeAdapter";
import FormatsMixins from "@/mixins/FormatsMixins";

export default {
  name: "ManageCall",
  data() {
    return {
      call: {
        status: "ADVICED"
      },
      vessels: [],
      vesselSelected: null,
      searchVessel: null,
      vesselSelectedBkp: null,
      loading: false,
      callId: null,
      status: null,
    };
  },
  async mounted() {

    const status = this.$route.query.status;
    console.log("Received status: ", status);
    this.status = status;

    const callId = this.$route.query.callId;
    console.log("Received callId:", callId);
    this.callId = callId;
    try {
      if (callId) {
        this.call = await this.$api.vesselData.getSimdCall("DISCHARGED", callId);
        if (!this.call) {
          throw new Error("Chiamata non trovata");
        }
        console.log("call", this.call, JSON.stringify(this.call));

        if (this.call.vessel) {
          this.vesselSelected = this.call.vessel; 
          console.log("Selected vessel:", this.vesselSelected);
        }
      }
    } catch (error) {
      console.error("Errore durante il recupero della chiamata:", error);
    }
    // this.vessels = await this.$api.vesselData.getVessels("di");
  },

  watch: {
    async searchVessel(newVal) {
      console.log("searchVessel", newVal);
      this.vessels = [];
      if (newVal !== null && newVal.length >= 3) {
        this.vessels = await this.$api.vesselData.getVessels(newVal);
      }
    },
  },
  components: { DateTimeAdapter },
  mixins: [FormatsMixins],
  methods: {
    goToCalls() {
      console.log("Navigating back with:", { callId: this.callId, status: this.status, attrs: this.attrs });

      if (this.callId) {

        if (!this.status) {
          console.error("Status is required for routing to CallDetails but is undefined.");
          return;
        }
        // Reindirizza alla pagina CallDetails se callId non è null
        this.$router.push({
          name: "CallDetails",
          params: {
            status: this.status,
            callId: this.callId,
          },
        }).catch((err) => {
          console.error("Routing error to CallDetails:", err);
        });
      } else {
        // Reindirizza alla pagina CallsDaily se callId è null
        this.$router.push({
          name: "CallsDaily",
          params: {
            attrs: this.attrs,
          },
        }).catch((err) => {
          console.error("Routing error to CallsDaily:", err);
        });
      }
    },
    onChangeVessel(item) {
      console.log("onChangeVessel", item);
      if (item && item !== null) {
        if (typeof item == "string") {
          console.log("onChangeVessel is string!");
          if (this.vesselSelectedBkp !== null && typeof this.vesselSelectedBkp != "string") {
            this.vesselSelected = JSON.parse(JSON.stringify(this.vesselSelectedBkp));
            this.searchVessel = this.vesselSelected.name;
          } else {
            this.vesselSelected = this.vesselSelectedBkp = null;
            this.searchVessel = null;
          }
        } else {
          this.vesselSelectedBkp = JSON.parse(JSON.stringify(item));
        }
      } else {
        this.vesselSelectedBkp = null;
      }
    },
    async saveCall() {
      console.log("call to save", this.call);

      const valid = this.$refs.editForm.validate();
      if (!valid) {
        return;
      }
      if (!this.call.code) {
        this.call.code = "PROVA";
      }

      // if (this.status == null) {
      //   this.status = "ADVISED"; 
      // }


      if (this.vesselSelected) {
        this.call.vessel= this.vesselSelected; 
      }

      // const date = new Date(inputDate);

      // Conversione della data in formato ISO, che include già l'offset UTC (Z)
      if (this.call.eta) {
        const etaDate = new Date(this.call.eta);
        console.log("eta formatted", etaDate.toISOString().replace(/\.\d{3}Z$/, ".000Z"));
        this.call.eta = etaDate.toISOString().replace(/\.\d{3}Z$/, ".000Z");
      }
      
      if (this.call.etd) {
        const etdDate = new Date(this.call.etd);
        console.log("etd formatted", etdDate.toISOString().replace(/\.\d{3}Z$/, ".000Z"));
        this.call.etd = etdDate.toISOString().replace(/\.\d{3}Z$/, ".000Z");
      }

      console.log("Payload inviato al backend:", JSON.stringify(this.call));

      console.log("call modified to save", this.call);
      await this.$api.vesselData.saveCall(this.call);
      //   this.goToCalls();
    },
  },
};
</script>

<style></style>
