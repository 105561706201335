<template>
  <v-hover v-slot="{ hover }" open-delay="50" >
    <v-card :elevation="hover ? 6 : 2" rounded="0" class="mb-0" :color="hover ? 'grey lighten-5' : null">
      <v-card-text>
        <v-row align="center">
          <v-col cols="4" class="text-center py-0">
            <v-card elevation="0" rounded="0">
              <v-card-text :class="dischargeStatusColor(call.status) + ' lighten-4'">
                <v-row align="center">
                  <v-col cols="12" class="text-h6 font-weight-regular py-0 d-flex align-content-center justify-start flex-wrap">
                    {{ call.voyageIn }} <v-icon :color="dischargeStatusColor(call.status) + ' darken-2'" class="ml-2">{{ dischargeStatusIcon(call.status) }}</v-icon>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="12" class="text-body-1 font-weight-light text-left py-0"> UTI: {{ utiDischargeTotalCounts.worked }} / {{ utiDischargeTotalCounts.total }} </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="12" :class="'text-h3 font-weight-black text-right py-0 my-0 ' + dischargeStatusColor(call.status) + 'BgFont--text'"> DISCHARGE </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="1" class="text-center py-0"
            ><v-icon large :color="call.status === 'DISCHARGING' ? 'orange darken-2' : 'grey lighten-1'">mdi-arrow-bottom-left-thick</v-icon></v-col
          >
          <v-col cols="2" class="text-center py-0">
            <v-container fluid>
              <v-row align="center">
                <v-col cols="12" :class="'text-h5 font-weight-regular text-center ' + callStatusColor(call.status) + 'Font--text pb-0'">
                  {{ call.vessel.name }}
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12" class="text-center">
                  <v-icon x-large :color="callStatusColor(call.status) + ' darken-2'">{{ callStatusIcon(call.status) }}</v-icon>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12" :class="'text-body-1 font-weight-bold text-center ' + callStatusColor(call.status) + 'Font--text pt-0'"> {{ call.status }} </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="1" class="text-center py-0"><v-icon large :color="call.status === 'LOADING' ? 'orange darken-1' : 'grey lighten-1'">mdi-arrow-top-left-thick</v-icon></v-col>
          <v-col cols="4" class="text-center py-0">
            <v-card elevation="0" rounded="0">
              <v-card-text :class="loadStatusColor(call.status) + ' lighten-4'">
                <v-row align="center">
                  <v-col cols="12" class="text-h6 font-weight-regular py-0 d-flex align-content-center justify-start flex-wrap">
                    {{ call.voyageOut }} <v-icon :color="loadStatusColor(call.status) + ' darken-2'" class="ml-2">{{ loadStatusIcon(call.status) }}</v-icon>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="12" class="text-body-1 font-weight-light text-left py-0"> UTI: {{ utiLoadTotalCounts.worked }} / {{ utiLoadTotalCounts.total }} </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="12" :class="'text-h3 font-weight-black text-right py-0 my-0 ' + loadStatusColor(call.status) + 'BgFont--text'"> LOAD </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="!Object.keys(call.utiDischargeCountMap).length">
          <v-col cols="12" class="text-center text-body-1 grey--text">
            No data available.
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" class="text-body-1 pt-0 pb-2 d-flex align-content-center justify-center flex-wrap">
            {{ call.eta | formatDateTimeFromDate }} <v-icon color="secondary" class="mx-6">mdi-ray-end</v-icon> {{ call.position }}
            <v-icon color="secondary" class="mx-6">mdi-ray-start</v-icon> {{ call.etd | formatDateTimeFromDate }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import FormatMixins from "../../mixins/FormatsMixins";
import VesselMixin from "../../mixins/vessel/VesselMixin";

export default {
  name: "CallCard",
  data() {
    return {};
  },
  props: {
    call: {
      type: Object,
      required: true,
    },
    backAttrs: {
      type: Object,
      default: null,
    },
  },
  mixins: [FormatMixins, VesselMixin],
  computed: {
    utiDischargeTotalCounts() {
      return this.call.utiDischargeCountMap?.['TOT']?.['TOT'] || { worked: 0, total: 0 };
    },
    utiLoadTotalCounts() {
      return this.call.utiLoadCountMap?.['TOT']?.['TOT'] || { worked: 0, total: 0 };
    }
  }
};
</script>

<style scoped></style>
