<template>
  <div>
    <v-container fluid style="height: calc(100vh - 88px)" class="grey lighten-4" v-if="call">
      <v-row align="center" no-gutters>
        <v-col cols="12">
          <v-card rounded="0">
            <v-card-text>
              <v-container fluid class="my-0 py-0">
                <v-row align="center">
                  <v-col cols="1" class="d-flex align-center">
                    <v-icon size="35" @click="goToCalls">mdi-arrow-left</v-icon>
                    <v-icon color="grey lighten-1" class="ml-8" size="50">mdi-ferry</v-icon>
                  </v-col>
                  <v-col cols="2">
                    <div class="text-subtitle-1">{{ call.vessel.name }}</div>
                    <div class="text-subtitle-2 font-weight-light">{{ call.position }}</div>
                  </v-col>
                  <v-col cols="2">
                    <div class="text-subtitle-2">{{ call.operator }}</div>
                    <div class="text-subtitle-2">{{ call.agent }}</div>
                  </v-col>
                  <v-col cols="2">
                    <div class="text-subtitle-2">ETA: {{ call.eta | formatDateTimeFromDate }}</div>
                    <div class="text-subtitle-2">ATA: {{ call.ata | formatDateTimeFromDate }}</div>
                  </v-col>
                  <v-col cols="2">
                    <div class="text-subtitle-2">ETD: {{ call.etd | formatDateTimeFromDate }}</div>
                    <div class="text-subtitle-2">ATD: {{ call.atd | formatDateTimeFromDate }}</div>
                  </v-col>
                  <v-divider vertical class="my-2"/>
                  <v-col cols="2">
                    <div class="text-subtitle-2 pl-4">BEGIN: {{ call.beginOperation | formatDateTimeFromDate }}</div>
                    <div class="text-subtitle-2 pl-4">END: {{ call.endOperation | formatDateTimeFromDate }}</div>
                  </v-col>
                  <!-- <v-col cols="1" class="text-right">                   
                    <v-icon color="primary" size="30">mdi-cog-outline</v-icon>
                  </v-col> -->
                  <v-col cols="1" class="text-right">
                    <v-icon color="primary" size="50" @click="goToManageCallEditing" >
                      mdi-pencil-circle
                    </v-icon>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row align="center" no-gutters style="height: calc(100vh - 192px)">
        <v-col cols="12" class="fill-height">
          <v-container fluid class="fill-height mx-0 px-0">
            <v-row no-gutters class="fill-height">
              <v-col cols="12">
                <v-tabs v-model="tab" left background-color="panelheader">
                  <v-tab class="text-h6 text-uppercase">{{ tLabel("Discharge") }}</v-tab>
                  <v-tab class="text-h6 text-uppercase">{{ tLabel("Load") }}</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" class="fill-height scroller" style="height: calc(100vh - 254px)">
                  <v-tab-item class="fill-height" :class="dischargeStatusColor(call.status) + ' lighten-5'">
                    <VoyagePanel :voyage="voyageIn" />
                  </v-tab-item>
                  <v-tab-item class="fill-height" :class="loadStatusColor(call.status) + ' lighten-5'">
                    <VoyagePanel :voyage="voyageOut" />
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import VesselMixin from "../../mixins/vessel/VesselMixin";
import FormatsMixins from "../../mixins/FormatsMixins";
import VoyagePanel from "../../components/vessel/VoyagePanel.vue";

export default {
  name: "CallDetails",
  data() {
    return {
      call: null,
      tab: null,
      dischargeSearchFilter: null,
      utiDischargeFiltered: [],
      loadSearchFilter: null,
      utiLoadFiltered: [],
    };
  },
  props: {
    callId: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    attrs: {
      type: Object,
      default: null,
    },
  },
  mixins: [VesselMixin, FormatsMixins],
  components: {VoyagePanel},
  async mounted() {
    // this.call = await this.$api.vesselData.getCallById(this.callId);
    this.call = await this.$api.vesselData.getSimdCall(this.status, this.callId);
    console.log("call", this.call, JSON.stringify(this.call));
  },
  computed: {
    voyageIn() {
      if (!this.call) {
        return null;
      }
      return {
        code: this.call.voyageIn,
        direction: "ARRIVAL",
        callStatus: this.call.status,
        utiList: this.call.dischargeUti,
        utiCountMap: this.call.utiDischargeCountMap
      };
    },
    voyageOut() {
      if (!this.call) {
        return null;
      }
      return {
        code: this.call.voyageOut,
        direction: "DEPARTURE",
        callStatus: this.call.status,
        utiList: this.call.loadUti,
        utiCountMap: this.call.utiLoadCountMap
      };
    }
  },
  methods: {
    goToCalls() {
      if (this.attrs) {
      }
      this.$router.push({
        name: "CallsDaily",
        params: {
          attrs: this.attrs,
        },
      });
    },
    goToManageCallEditing() {
      // Redirecting to the ManageCall page in editing mode
      console.log("Navigating to EditCall with callId:", this.callId);
      this.$router.push({
        name: "EditCall",
        query: { 
          callId: this.callId, 
          status: this.status, 
        }
      });
    }
  },
};
</script>

<style scoped>
.scroller {
  overflow-y: auto;
  scrollbar-width: thin;
}

.scroller::-webkit-scrollbar {
  width: 6px;
}

.scroller::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroller::-webkit-scrollbar-thumb {
  background: #888;
}

.scroller::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.pointer {
  cursor: pointer;
}
</style>
