export default [
  {
    name: "Vessels",
    path: "/vessel/vessels",
    view: "vessel/Vessels",
    meta: { requiresAuth: true },
  },
  {
    name: "CallsDaily",
    path: "/vessel/callsDaily",
    view: "vessel/CallsDaily",
    meta: { requiresAuth: true },
  },
  {
    name: "CallsDaily",
    path: "/vessel/callsDaily",
    view: "vessel/CallsDaily",
    props: (route) => ({
      ...route.params,
    }),
    meta: { requiresAuth: true },
  },
  {
    name: "CallDetails",
    path: "/vessel/callDetails/:status/:callId",
    view: "vessel/CallDetails",
    props: (route) => ({
      ...route.params,
    }),
    meta: { requiresAuth: true },
  },
  {
    name: "CreateCall",
    path: "/vessel/manageCall",
    view: "vessel/ManageCall",
    meta: { requiresAuth: true },
  },
  {
    name: "EditCall",
    path: "/vessel/manageCall/:callId",
    view: "vessel/ManageCall",
    meta: { requiresAuth: true },
  }
];
