import { render, staticRenderFns } from "./CallDetails.vue?vue&type=template&id=14e27aea&scoped=true&"
import script from "./CallDetails.vue?vue&type=script&lang=js&"
export * from "./CallDetails.vue?vue&type=script&lang=js&"
import style0 from "./CallDetails.vue?vue&type=style&index=0&id=14e27aea&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14e27aea",
  null
  
)

export default component.exports